<template>
	<table>
		<tbody>
			<tr v-for="item in dataTable" :key="item.text">
				<td class="text">{{ item.text }}</td>
				<td class="value">{{ item.value }}</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	name: 'BaseTableTransparency',
	props: {
		dataTable: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {}
	},
}
</script>

<style lang="scss" scoped>
table {
	width: 100%;
	td {
		padding: 8px 0;
		&.text {
			font-size: 14px;
			font-weight: 500;
			color: #17141a;
			font-family: 'Montserrat';
		}
		&.value {
			font-size: 14px;
			font-weight: 600;
			color: #17141a;
			text-align: right;
		}
	}
}
</style>
