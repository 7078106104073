<template>
	<div class="base-left">
		<template v-for="(item, index) in sideLeft" :key="index">
			<h3>{{ item.title }}</h3>
			<div class="description">
				{{ item.description }}
			</div>
			<div class="social-links">
				<div
					v-for="link in item.links"
					:key="link.text"
					class="social-item"
				>
					<div>
						<img :src="`/img/icon/${link.icon}`" alt="" />
						<span class="label">{{ link.text }}</span>
					</div>
					<a
						:href="link.url"
						target="_blank"
						ref="noopener noreferrer"
					>
						<img src="@/assets/img/dao/share.svg" alt="" />
					</a>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	name: 'BaseLeft',
	data() {
		return {
			sideLeft: [
				{
					title: 'Currently funding initiatives',
					description:
						'Our community is powered by individual contributors all pushing in the same direction.',
					links: [
						{
							icon: 'folder-open.svg',
							text: 'View all proposals',
							url: '#',
						},
					],
				},
				{
					title: 'We do have brain-power',
					description:
						'This set of individuals was chosen by the community to represent them.',
					links: [
						{
							icon: 'file-text.svg',
							text: 'How the DAO works',
							url: '#',
						},
					],
				},
			],
		}
	},
}
</script>

<style lang="scss" scoped>
.base-left {
	min-width: 280px;
	max-width: 360px;
	font-family: 'Montserrat';
	:first-child {
		margin-top: 0 !important;
	}
	h3 {
		font-size: 18px;
		line-height: 1.5;
		color: #000;
		margin-top: 20px;
	}
	.description {
		font-size: 13px;
		line-height: 1.5;
		font-weight: 400;
		color: #747276;
	}
	.social-links {
		margin-top: 20px;
		.social-item {
			width: 100%;
			display: flex;
			justify-content: space-between;
			border: 1px solid #efeeef;
			border-radius: 4px;
			margin-top: 8px;
			padding: 8px 12px;
			img {
				top: 2px;
				width: 20px;
				height: 20px;
			}
			.label {
				top: -2px;
				margin-left: 8px;
				font-size: 14px;
				color: #454348;
			}
		}
	}
}
@include media-max($media_large) {
	// 1200
}

@include media-max($media_medium) {
	// 1024
}

@include media-max($media_small) {
	// 768
}
</style>
