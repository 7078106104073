<template>
	<div class="title-box">
		<HeaderTab />
	</div>
	<div class="transparency">
		<div class="transparency-wrap">
			<SideLeft />
			<MainTransparency />
		</div>
	</div>
	<Foot :theme="'white'" />
</template>

<script>
import Foot from '@/components/FOOT.vue'
import SideLeft from '@/components/Transparency/SideLeft.vue'
import MainTransparency from '@/components/Transparency/MainTransparency.vue'
import HeaderTab from '@/components/HeaderTab.vue'

export default {
	name: 'TransparencyPage',
	components: {
		Foot,
		SideLeft,
		MainTransparency,
		HeaderTab
	},
	data() {
		return {}
	},
	beforeMount() {
		if (!this.$store.state.userInfo.wallet_addr) {
			this.$router.push({
				name: 'Catalyst',
			})
		}
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.title-box {
	@include FLEXV(flex-start, center);
	width: 100%;
	height: gREm(204);
	background-image: url('../assets/img/dao/header-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	.title-wrap {
		@include FLEXV(center, center);
		width: auto;
		height: gREm(95);
		margin-top: gREm(215);
		.title {
			height: auto;
			width: gREm(322);
			@include Set-Font($AppFont, gREm(34), gREm(52), #ffffff, 600);
			text-align: center;
		}
		.sub-title {
			height: auto;
			width: auto;
			@include Set-Font($AppFont, gREm(34), gREm(52), #ffffff);
			text-align: center;
		}
	}
}

.transparency {
	font-family: 'Montserrat';
	background: white;
	min-height: calc(100vh - 144px - 237px);
	.transparency-wrap {
		display: flex;
		gap: 20px;
		width: 100%;
		max-width: 1180px;
		height: auto;
		margin: 0 auto;
		padding: 30px 20px;
	}
}

@include media-max($media_large) {
	// 1200
}

@include media-max($media_medium) {
	// 1024
}

@include media-max($media_small) {
	// 768
	.transparency-wrap {
		flex-direction: column;
		padding: 20px !important;
	}
}
</style>
