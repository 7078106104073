<template>
	<div class="balance">
		<div class="current-balance">
			<h3>Current Balance</h3>
			<div class="assets">
				<div
					class="asset-item"
					v-for="asset in currentAssets"
					:key="asset.key"
				>
					<img
						class="asset-icon"
						:src="`/img/icon/${asset.icon}`"
						alt=""
					/>
					<div class="asset-info">
						<span class="asset-name"
							>{{ asset.name }} Tokens
							<a href="#">
								<img src="@/assets/img/dao/info.svg" alt="" />
							</a>
						</span>
						<h4 class="asset-balance">{{ asset.balance }}</h4>
					</div>
				</div>
			</div>
		</div>
		<div class="balance-statistic">
			<div class="balance-statistic-box">
				<div class="header">
					<div class="label">Last 30 Days Income</div>
					<h4>{{ box1Data.total }} <span class="unit">USD</span></h4>
					<div class="growth-rate">
						<span class="percent">{{ box1Data.growthRate }}</span>
						vs Previous 30 days
					</div>
				</div>
				<div class="body">
					<BaseTable :dataTable="box1Data.detail" />
				</div>
			</div>
			<div class="balance-statistic-box">
				<div class="header">
					<div class="label">Last 30 Days Income</div>
					<h4>{{ box2Data.total }} <span class="unit">USD</span></h4>
					<div class="growth-rate">
						<span class="percent">{{ box2Data.growthRate }}</span>
						vs Previous 30 days
					</div>
				</div>
				<div class="body">
					<BaseTable :dataTable="box2Data.detail" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseTable from './BaseTable.vue'
export default {
	name: 'Balance',
	components: {
		BaseTable,
	},
	data() {
		return {
			currentAssets: [
				{
					key: 'dvi',
					icon: 'dvi.svg',
					name: 'DVI',
					balance: '44,148,021.234',
				},
				{
					key: 'eth',
					name: 'ETH',
					icon: 'ethereum.svg',
					balance: '5.153',
				},
				{
					key: 'usdt',
					icon: 'usdt.svg',
					name: 'USDT',
					balance: '44,148,021.234',
				},
				{
					key: 'usdc',
					name: 'USDC',
					icon: 'usdc.svg',
					balance: '5.153',
				},
			],
			box1Data: {
				total: '$ 4,445,712',
				growthRate: '-20%',
				detail: [
					{ text: 'Vesting Contact', value: '$4.183.426' },
					{ text: 'ETH DCL Marketplace', value: '$123,109' },
					{ text: 'MATIC DCL Marketplace', value: '$1,486' },
					{ text: 'OpenSea Marketplace', value: '$0' },
					{ text: 'Other', value: '$137,692' },
				],
			},
			box2Data: {
				total: '$ 4,445,712',
				growthRate: '-20%',
				detail: [
					{ text: 'Curation Committee', value: '$4.183' },
					{ text: 'DAO Facilitator', value: '$3,109' },
					{ text: 'Grants', value: '$512,486' },
					{ text: 'Other', value: '$137,692' },
				],
			},
		}
	},
}
</script>

<style lang="scss" scoped>
.balance {
	width: 100%;
	.current-balance {
		background: #f9fafb;
		border: 1px solid #efeeef;
		border-radius: 8px;
		padding: 20px;
		h3 {
			font-size: 18px;
			line-height: 1.5;
			color: #000;
		}
		.assets {
			margin-top: 20px;
			display: grid;
			grid-template-columns: auto auto;
			gap: 20px;
			.asset-item {
				grid-column: span 1;
				display: flex;
				align-items: center;
				.asset-icon {
					width: 35px;
					height: 35px;
				}
				.asset-info {
					margin-left: 10px;
					.asset-name {
						font-size: 14px;
						color: #747276;
						img {
							top: 3px;
							width: 18px;
							height: 18px;
						}
					}
					.asset-balance {
						font-size: 16px;
						font-weight: 700;
						line-height: 1.5;
						color: #17141a;
					}
				}
			}
		}
	}
	.balance-statistic {
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		gap: 20px;
	}
	.balance-statistic-box {
		width: 50%;
		border: 1px solid #efeeef;
		border-radius: 8px;
		.header {
			background: #f9fafb;
			padding: 16px 20px;
			border-bottom: 1px solid #efeeef;
			.label {
				font-size: 14px;
				font-weight: 500;
				color: #747276;
			}
			h4 {
				font-size: 24px;
				font-weight: 600;
				line-height: 1.5;
				color: #17141a;
				.unit {
					font-size: 14px;
					font-weight: 400;
					color: #747276;
				}
			}
			.growth-rate {
				font-size: 14px;
				color: #747276;
				.percent {
					font-weight: 600;
					color: #f87171;
				}
			}
		}
		.body {
			background: #fff;
			padding: 16px 20px;
		}
	}
}
@include media-max($media_large) {
	// 1200
}

@include media-max($media_medium) {
	// 1024
}

@include media-max($media_small) {
	// 768
	.balance {
		.balance-statistic {
			flex-direction: column;
		}
		.balance-statistic-box {
			width: 100%;
		}
	}
}

@include media-max(500px) {
	.balance {
		.current-balance {
			.assets {
				.asset-item {
					grid-column: span 2;
				}
			}
		}
	}
}
</style>
